import React, { Component } from 'react'

class Schedule extends Component {
  state = {
    count: 0,
  }

  render() {
    return (
      <div>
        {this.getContent()}
      </div>
    )
  }

  getContent() {
    if (this.state.count <= 0) {
      return (
        <div>
          <p>現在予定しているイベントはありません。</p>
        </div>
      )
    } else {
      return (
        <div>
          <p>current cnt is {this.state.count}</p>
        </div>
      )
    }
  }
}

export default Schedule
