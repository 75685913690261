import React, { Component } from 'react'
import { Link } from 'gatsby'
class News extends Component {
  constructor(props) {
    super(props)
    this.setState({ activepage: 1 })
  }

  handlePageChange = page => {
    this.setState({ activePage: page }) //Flips true/false
  }

  render() {
    return (
      <div>
        {this.props.data.allMarkdownRemark.edges.map(post => (
          <div className="news" key={post.node.id}>
            <h2 className="news-title">{post.node.frontmatter.title}</h2>
            <div className="news-body">
              <div dangerouslySetInnerHTML={{ __html: post.node.html }} />
            </div>
            <small className="postedby">
              Posted by {post.node.frontmatter.author} on{' '}
              {post.node.frontmatter.date}
            </small>
          </div>
        ))}
        <div className="link-archive">
          <h5>
            <Link to="/archive">archive</Link>
          </h5>
        </div>
      </div>
    )
  }
}

export default News
