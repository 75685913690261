import React, { Component } from 'react'
import ashiato from '../images/ashiato.png'

class Music extends Component {
  state = {}

  render() {
    return (
      <div>
        <div className="">
          <iframe
            allowTransparency="true"
            width="90%"
            height="100"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/587386950&color=%23554141&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&show_artwork=false"
          />
        </div>
        <hr />
        <div className="content">
          <div className="news">
            <h2>あしあと</h2>
            <p>2017 Music & Lyrics by Spherebox</p>

            <span className="image">
              <img src={ashiato} alt="" width="50%" height="50%" />
            </span>
            <ol>
              <li>あしあと</li>
              <li>つくし畑</li>
              <li>忘却の街</li>
              <li>銀のメダル</li>
            </ol>
          </div>
        </div>
      </div>
    )
  }
}

export default Music
