import React from 'react'
import PropTypes from 'prop-types'
import News from './News'
import Schedule from './Schedule'
import Music from './Music'

import pic01 from '../images/01.jpg'
import { Link } from 'gatsby'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>Spherebox (すふぃあぼっくす|すふぃぼ)</p>
          <p>
            an(vo), chisa(pf),
            tato(gt)から成るアコースティックトリオ。確か2013年頃結成。
            月1のライブを目標に活動していたが、直近の実績は年1回のマイペースバンド。
          </p>
          <p>
            ワイワイ盛り上がる感じではなく、ソファにゆったりと座ってお酒を飲みながら楽しんでもらう系統。
            無理なく、気張りなく。自分たちも楽しく、お客さんにも喜んでもらえるような音楽を模索中。
          </p>
          <p>今後の挽回に期待。</p>

          {close}
        </article>

        <article
          id="news"
          className={`${this.props.article === 'news' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">News</h2>
          <News activePage={this.props.activePage} data={this.props.data} />
          {close}
        </article>

        <article
          id="music"
          className={`${this.props.article === 'music' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Music</h2>
          <Music />
          {close}
        </article>

        <article
          id="schedule"
          className={`${this.props.article === 'schedule' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Schedule</h2>
          <Schedule />
          {close}
        </article>
        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" />
            </div>
            <ul className="actions">
              <li>
                <input type="submit" onClick={this.alertBox} value="Send Message" className="special" />
                {/* <input type="submit" value="Send Message" className="special" /> */}
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {close}
        </article>
      </div>
    )
  }
  alertBox = (e) => {
    e.preventDefault()

    alert('準備中なり。 Sorry!')
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
